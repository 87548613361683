import React from "react";

import FooterLogo from "../assets/images/logo-1-1.png";

const Footer = () => {
  return (
    <div>
      

      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <a href="index.html">
                    <img src={FooterLogo} width="129" alt="awesome post" />
                  </a>
                  <p>
                  Somos uma empresa séria, <br/>com mais de 10 anos no mercado, <br/>desenvolvendo sites, sistemas e aplicativos
                  </p>
                </div>
              </div>

              <div className="col-lg-5">                
                <p>edwin@medinaweb.com.br</p>
                <p>Whatsapp: (66) 99686-6082</p>
                <p>De SEG-SEX 7:30 - 17:30</p>
              </div>
              <div className="col-lg-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center">
                <div className="footer-widget">
                  <div className="footer-widget__social">
                    <a href="https://www.facebook.com/medinawebsites" target="blank">
                      <i className="fab fa-facebook-square"></i>
                    </a>                    
                    <a href="https://www.instagram.com/medinaweb.br/" target="blank">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCAHNN-bRW8mIczd7yqIQyMg" target="blank">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container text-center">
            <p>© copyright 2021 by medinaweb</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
